<template>
  <main class="container">
    <ContentHeader :title="content_header.title" />

    <div class="page__body">
      <div class="page__body__header">
        <div class="page__head__actions">
          <div class="d-flex justify-content-end">
            <div class="align-self-center mx-2 dropdown">
              <button
                type="button"
                class="
                  w-100
                  button
                  page__body__header__button
                  button--grey
                  text--capital
                "
                @click="toggleFilterModal"
              >
                <span class="d-inline-flex mr-2">filter</span>
              </button>
            </div>

            <div class="align-self-center mx-2 dropdown">
              <button
                class="
                  button button--grey
                  text--capital text--small
                  d-flex
                  align-items-center
                "
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                export
                <span class="dropdown__icon">
                  <ion-icon name="caret-down-outline"></ion-icon>
                </span>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a
                  href="javascript:void(0)"
                  class="dropdown-item text--capital"
                  v-if="userCanExportToPDF"
                  @click="pdfDownload"
                  >PDF</a
                >
                <a
                  href="javascript:void(0)"
                  class="dropdown-item text--capital"
                  v-if="userCanExportToExcel"
                  @click="processDownload"
                  >excel</a
                >
                <!-- <download-excel
                  class="dropdown-item text--capital"
                  style="cursor: pointer"
                  :data="download"
                  :fields="json_fields"
                  name="disbursement.xls"
                >
                  excel
                </download-excel> -->
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="row float-right">
          <div class="col-5 ml-auto">
            <div class="row align-items-center justify-content-between">
              <div class="col-5">
                <button
                    type="button"
                    class="w-100 button page__body__header__button button--grey text--capital"
                  >
                    <span class="d-inline-flex mr-2">filter by</span>
                    <ion-icon name="caret-down-outline"></ion-icon>
                  </button>
                <b-dropdown
                  :html="filter_by_label"
                  no-caret
                  class="
                    m-2
                    page__body__header__button
                    dropdown--button-grey
                    w-100
                  "
                  v-model="sort"
                >
                  <b-dropdown-item
                    v-for="item in cats"
                    :key="item.value"
                    v-bind:value="item.value"
                    @click="filterByCategory(item.value, item.text)"
                    >{{ item.text }}</b-dropdown-item
                  >
                </b-dropdown>
              </div>
            </div>
          </div>
        </div> -->
      </div>

      <div style="display:flex; width:50%; font-size:14px" v-if="filterText">
        <div style="width:80%">
          {{ filterText }}
        </div>
        <div style="display:flex;width:10%">
          <span style="cursor:pointer;font-size:17px" @click="editFilter">
            <i style="padding-right:5px" class="bi bi-pencil-square"></i>
          </span>
          <span style="cursor:pointer;font-size:17px" @click="deleteFilter">
            <i style="padding-left:5px" class="bi bi-x-circle-fill"></i>
          </span>
        </div>
      </div>

      <div class="mt5 w-100" style="overflow-x: scroll">
        <table class="customtable _customtable">
          <thead>
            <tr>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
              >
                customer name
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
              >
                loan product
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
              >
                amount
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
              >
                bank account name
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
              >
                bank account
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
              >
                bank name
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
              >
                status
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
              >
                disbursement date
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
              >
                actions
              </th>
            </tr>
          </thead>
          <tbody v-if="!disbursements || disburseLoading">
            <tr>
              <td colspan="4">
                <div
                  class="d-flex align-items-center justify-content-center p5"
                >
                  <div
                    class="spinner-border"
                    style="width: 3rem; height: 3rem"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                  <span
                    class="
                      text--black text--600 text--medium
                      d-inline-flex
                      ml-4
                    "
                    >Please wait</span
                  >
                </div>
              </td>
            </tr>
          </tbody>

          <tbody v-else-if="disbursements != ''">
            <tr v-for="(disbursement, i) in disbursements" :key="i">
              <td>
                <span style="word-break: break-word" class="table__body__text table__text text--capital">{{
                  disbursement.customer_name
                }}</span>
              </td>
              <td>
                <span style="word-break: break-word" class="table__body__text table__text text--capital">{{
                  disbursement.associatedLoanAccount ? disbursement.associatedLoanAccount.loan_name : ""
                }}</span>
              </td>
              <td>
                <span class="table__body__text table__text text--capital">
                  {{ disbursement.disbursement_amount | currency }}
                </span>
              </td>
              <td>
                <span style="word-break: break-word" class="table__body__text table__text text--capital">
                  {{ disbursement.account_name }}
                </span>
              </td>
              <td>
                <span class="table__body__text table__text text--capital">
                  {{ disbursement.account_number }}
                </span>
              </td>
              <td>
                <span class="table__body__text table__text text--capital">
                  {{ disbursement.bank_name }}
                </span>
              </td>
              <td>
                <span
                  class="
                    table__body__text table__text
                    text--capital text--success
                  "
                  v-if="
                    disbursement.disbursement_status.toLowerCase() === 'success'
                  "
                  >{{ disbursement.disbursement_status }}</span
                >
                <span
                  class="
                    table__body__text table__text
                    text--capital text-danger
                  "
                  v-else-if="
                    disbursement.disbursement_status.toLowerCase() === 'failed'
                  "
                  >{{ disbursement.disbursement_status }}</span
                >
                <span
                  class="
                    table__body__text table__text
                    text--capital text-danger
                  "
                  v-else-if="
                    disbursement.disbursement_status.toLowerCase() === 'cancel'
                  "
                  >{{ disbursement.disbursement_status }}</span
                >
                <span
                  class="table__body__text table__text text--capital"
                  v-else
                  >{{ disbursement.disbursement_status }}</span
                >
              </td>
              <td v-if="disbursement.disbursement_date === ''">
                <span class="table__body__text table__text text--capital">
                </span>
              </td>
              <td v-else>
                <span class="table__body__text table__text text--capital">{{
                  disbursement.disbursement_date | moment
                }}</span>
              </td>
              <td>
                <div class="align-self-center dropdown">
                  <button
                    class="
                      button button--grey
                      text--capital text--small
                      d-flex
                      align-items-center
                    "
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    action
                    <span class="dropdown__icon">
                      <ion-icon name="caret-down-outline"></ion-icon>
                    </span>
                  </button>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a
                      class="dropdown-item text--capital"
                      @click="
                        openRetryConfimationModal(
                          disbursement.disbursement_transaction_key
                        )
                      "
                      v-if="
                        disbursement.disbursement_status.toLowerCase() ===
                        'failed'
                      "
                      >retry</a
                    >

                    <a
                      class="dropdown-item text--capital"
                      @click="
                        openActivateConfimationModal(
                          disbursement.disbursement_transaction_key
                        )
                      "
                      v-if="
                        (disbursement.disbursement_status.toLowerCase() ===
                        'failed' ||
                        disbursement.disbursement_status.toLowerCase() ===
                        'manual'
                        )
                      "
                      >Activate</a
                    >

                    <a
                      class="dropdown-item"
                      @click="
                        openCancelConfimationModal(
                          disbursement.disbursement_transaction_key
                        )
                      "
                      v-if="(
                          disbursement.disbursement_status.toLowerCase() === 'failed' ||
                          disbursement.disbursement_status.toLowerCase() === 'manual'
                        )"
                      >Cancel</a
                    >

                    <a
                      class="dropdown-item"
                      @click="
                        goToLoanDetails(
                          disbursement.disbursement_transaction_key,
                          disbursement.associatedLoanAccount.loan_account_key
                        )
                      "
                      v-if="disbursement.disbursement_status.toLowerCase() === 'manual'"
                      >Approve Disbursement</a
                    >

                    <a
                      class="dropdown-item"
                      @click="
                        openCancelConfimationModal(
                          disbursement.disbursement_transaction_key
                        )
                      "
                      v-if="disbursement.disbursement_status.toLowerCase() === 'pending'"
                      >Stop</a
                    >
                  </div>
                </div>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr>
              <td colspan="12">
                <div
                  class="
                    d-flex
                    flex-column
                    align-items-center
                    justify-content-center
                    p5
                  "
                >
                  <img src="@/assets/img/empty.svg" alt="delete" srcset="" />

                  <span
                    class="
                      d-block
                      text--danger text--600 text--medium
                      mt-3
                      mb-5
                    "
                    >No data available</span
                  >
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <Pagination
          :allItems="paginations"
          :size="size"
          @update-created-task="getDisbursements"
          @change-page-index="changePageIndex"
        />

        <!-- <div v-if="paginations && disbursements != ''" class="pagination">
          <div class="d-flex align-items-center justify-content-between w-100">
            <div class="text--capital text--regular pagination__text">
              row per page:
              <span
                class="d-inline-block text--primary"
                style="padding-right: 15px"
              >
                <select @change="changeLength" v-model="size">
                  <option :value="5">5</option>
                  <option :value="10">10</option>
                  <option :value="20">20</option>
                  <option :value="30">30</option>
                  <option :value="50">50</option>
                  <option :value="100">100</option>
                </select>
              </span>
              <span class="d-inline-block text--primary">{{
                !paginations.hasPreviousPage
                  ? paginations.pageIndex || paginations.currentPage
                  : size * ((paginations.pageIndex || paginations.currentPage)- 1) + 1
              }}</span>
              -
              <span class="d-inline-block text--primary">{{
                paginations.hasNextPage
                  ? size * (paginations.pageIndex || paginations.currentPage)
                  : paginations.totalCount
              }}</span>
              of
              <span>{{ paginations ? paginations.totalCount : 0 }}</span>
            </div>

            

            <div
              class="
                pagination__buttons
                d-flex
                align-items-center
                justify-content-between
              "
              v-if="paginations"
            >
              <button
                class="button button--sm text--regular"
                @click="changePage('previous')"
                :disabled="!paginations.hasPreviousPage"
              >
                <ion-icon name="chevron-back-outline"></ion-icon>
              </button>
              <button
                v-for="page in paginations.totalPages"
                :key="page"
                :class="{
                  'text--white button--primary ': paginations.pageIndex === page || paginations.currentPage === page,
                }"
                class="button text--regular button--sm ml-2"
                @click="changePage(page)"
              >
                {{ page }}
              </button>
              <button
                class="button text--regular button--sm ml-2"
                @click="changePage('next')"
                :disabled="!paginations.hasNextPage"
              >
                <ion-icon name="chevron-forward-outline"></ion-icon>
              </button>
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>

    <!-- Disbursement Filter -->
    <CreateFilter
      :show="showFilterModal"
      @close="toggleFilterModal"
      @send-activate-filter="sendFilter"
      @reload="clearFilter"
      @alertError="alertError"
      :filterEditValue="filterEdit"
      :showEditedValue="showEditedValue"
      :filterHeaderName="'Disbursement'"
      filterCategory="disbursement"
    />

    <!-- Retry confirmation -->
    <DeleteModal :show="openRetryModal" @close="closeRetryConfirmationModal">
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">
              Are you sure you want to retry this disbursement?
            </h5>
            <div class="d-flex mt-5">
              <button
                data-dismiss="#confirmModal"
                class="
                  button button--lg
                  modal__confirm__button modal__confirm__button--cancel
                  mr-2
                "
                @click="closeRetryConfirmationModal"
              >
                cancel
              </button>
              <button
                data-dismiss="#confirmModal"
                class="
                  button button--lg
                  modal__confirm__button modal__confirm__button--delete
                  ml-2
                  save-changes
                "
                @click="closeRetryConfirmationModal"
                v-if="isRetrying"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                data-dismiss="#confirmModal"
                class="
                  button button--lg
                  modal__confirm__button modal__confirm__button--delete
                  ml-2
                  save-changes
                "
                @click="retryDisbursement"
                v-else
              >
                Retry
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>

    <!-- Cancel confirmation -->
    <DeleteModal :show="openCancelModal" @close="closeCancelConfirmationModal">
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">
              Are you sure you want to cancel this disbursement
            </h5>
            <div class="d-flex mt-5">
              <button
                data-dismiss="#confirmModal"
                class="
                  button button--lg
                  modal__confirm__button modal__confirm__button--cancel
                  mr-2
                "
                @click="closeCancelConfirmationModal"
              >
                No
              </button>
              <button
                data-dismiss="#confirmModal"
                class="
                  button button--lg
                  modal__confirm__button modal__confirm__button--delete
                  ml-2
                  save-changes
                "
                @click="closeCancelConfirmationModal"
                v-if="isCancelling"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                data-dismiss="#confirmModal"
                class="
                  button button--lg
                  modal__confirm__button modal__confirm__button--delete
                  ml-2
                  save-changes
                "
                @click="cancelDisbursement"
                v-else
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>

    <!-- Activate confirmation -->
    <DeleteModal
      :show="openActivateModal"
      @close="closeActivateConfirmationModal"
    >
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">
              Are you sure you want to activate this disbursement?
            </h5>
            <div class="d-flex mt-5">
              <button
                data-dismiss="#confirmModal"
                class="
                  button button--lg
                  modal__confirm__button modal__confirm__button--cancel
                  mr-2
                "
                @click="closeActivateConfirmationModal"
              >
                cancel
              </button>
              <button
                data-dismiss="#confirmModal"
                class="
                  button button--lg
                  modal__confirm__button modal__confirm__button--delete
                  ml-2
                  save-changes
                "
                @click="closeActivateConfirmationModal"
                v-if="isActivated"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                data-dismiss="#confirmModal"
                class="
                  button button--lg
                  modal__confirm__button modal__confirm__button--delete
                  ml-2
                  save-changes
                "
                @click="activateDisbursement"
                v-else
              >
                Activate
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>
  </main>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";
// import JsonExcel from "vue-json-excel";
import * as XLSX from "xlsx"
import { Role } from "@/core/services/role.js";
import jsPDF from "jspdf";
import "jspdf-autotable";

import {
  GET_ALL_DISBURSEMENT,
  RETRY_DISBURSEMENT,
  CANCEL_DISBURSEMENT,
  ACTIVATE_DISBURSEMENT,
  GET_ALL_DISBURSEMENT_EXPORTS,
} from "@/core/services/store/disbursement.module";

export default {
  name: "Disbursement",
  components: {
    DeleteModal: () =>
      import(
        /* WebpackChunkName: "DeleteModal" */ "@/components/DeleteModal/_base-delete-modal.vue"
      ),
    // downloadExcel: JsonExcel,
    CreateFilter: () => 
      import(
        /* WebpackChunkName: "CreateFilter" */ "@/view/pages/deposit/filter.vue"
      ),

    Pagination: () =>
      import(
        /* WebpackChunkName: "Pagination" */ "@/components/UI/pagination.vue"
      ),
  },
  data() {
    return {
      content_header: {
        title: "Disbursement Manager",
      },
      alert: {
        show: false,
        status: "",
        title: "Success",
        description: "Deposit Product has been created",
      },
      filter_by_label: "Filter <ion-icon name='caret-down-outline'/>",
      sort: "",
      json_fields: {
        "Customer Name": "customer_name",
        "Loan Product": "loan_product",
        Amount: "amount",
        "Account Name": "account_name",
        "Bank Account": "bank_account",
        "Bank Name": "bank_name",
        Status: "status",
        "Creation Date": "date",
      },
      showFilterModal: false,
      cats: [
        {
          value: "customer_name",
          text: "Customer Name",
        },
        {
          value: "creation_date",
          text: "Creation Date",
        },
      ],
      fetchModel: {
        filters: [],
        pageIndex: 1,
        pageSize: 10,
        filter: "",
        filterCategory: "",
        disbursementMethod: "",
        isFromFilter: false,
      },
      pages: 1,
      size: 20,
      disbursementMethod: "",
      openRetryModal: false,
      openCancelModal: false,
      disbursementKey: "",
      isCancelling: false,
      isRetrying: false,
      openActivateModal: false,
      isActivated: false,
      exportData: [],
      filterText: "",
      filterEdit: [],
      showEditedValue: 0,
      disburseLoading: false,
    };
  },
  watch: {
    allDisbursementsExports(){
      this.filterText = this.allDisbursementsExports.filterText ? this.allDisbursementsExports.filterText.replace(/_/g, " ") : "";
      this.filterEdit = this.allDisbursementsExports.filters ? this.allDisbursementsExports.filters : [];
    }
  },
  computed: {
    ...mapState({
      disbursements: (state) => state.disbursement.allDisbursements,
      allDisbursementsExports: (state) => state.disbursement.allDisbursementsExports,
      paginations: (state) => state.disbursement.pagination,
      user: (state) => state.auth.user,
    }),
    userCanExportToExcel() {
      const user = this.user.scopes.find(item => item.scopeName === Role.Documents);
      const { permissionsList } = user;
      return permissionsList.CanExportToExcel;
    },

    userCanExportToPDF() {
      const user = this.user.scopes.find(item => item.scopeName === Role.Documents);
      const { permissionsList } = user;
      return permissionsList.CanDownloadDocument;
    },
    download() {
      const arrayData = this.allDisbursementsExports.items ? this.allDisbursementsExports.items 
        : this.allDisbursementsExports.data ? this.allDisbursementsExports.data : [] 
      let data = [];
      arrayData.forEach((item) => {
        let new_obj = {};

        new_obj.customer_name = item.customer_name;
        new_obj.loan_product = item.associatedLoanAccount ? item.associatedLoanAccount.loan_name : "";
        new_obj.amount = item.disbursement_amount;
        new_obj.account_name = item.account_name;
        new_obj.bank_account = item.account_number;
        new_obj.bank_name = item.bank_name;
        new_obj.status = item.disbursement_status;
        new_obj.date = moment(item.disburse_log_date).format("Do-MMM-YYYY");

        data.push(new_obj);
      });

      return data;
    },
  },
  methods: {
    filterByCategory(id, name) {
      this.filter_by_label = name + " <ion-icon name='caret-down-outline'/>";
      this.fetchModel.filterBy = id;
      this.getAllDisbursements();
    },
    toggleFilterModal() {
      this.showFilterModal = !this.showFilterModal;
    },
    sendFilter(value) {
      const filterArray = value.map(obj => {
        const payload = {};

        payload.filterFieldName = obj.filterName;
        payload.filterFieldOperator = obj.filterOp;
        if (obj.value1 === '' || obj.value1 === null) {
          payload.filterFieldValues = [obj.value];
          return payload;
        }
        payload.filterFieldValues = [obj.value, obj.value1];
        return payload;
      });
      this.fetchModel.filters = filterArray;
      this.fetchModel.filterCategory = "disbursement";
      this.fetchModel.isFromFilter = true;
      this.fetchModel.pageIndex = 1;
      this.fetchModel.pageSize = 10;
      this.getAllDisbursements();
    },
    clearFilter() {
      this.deleteFilter()
      this.fetchModel.isFromFilter = false;
      this.getAllDisbursements();
    },
    processDownload() {
      let wb = XLSX.utils.book_new();

      wb.Props = {
        Title: "Disbursements List Download",
        Subject: "Disbursements List Excel Sheet",
        Author: "Prolean",
        createdDate: Date.now(),
      };

      wb.SheetNames.push("Disbursements");

      let sheetData = [[
        "Customer Name",
        "Loan Product",
        "Amount",
        "Account Name",
        "Bank Account",
        "Bank Name",
        "Status",
        "Creation Date",
      ]];

      const arrayData = this.allDisbursementsExports.items ? this.allDisbursementsExports.items 
        : this.allDisbursementsExports.data ? this.allDisbursementsExports.data : [];

      arrayData.map((item) => {
        let new_arr = [];
        new_arr.push(item.customer_name);
        new_arr.push(
          item.associatedLoanAccount ? item.associatedLoanAccount.loan_name : ""
        );
        new_arr.push(item.disbursement_amount);
        new_arr.push(item.account_name);
        new_arr.push(item.account_number);
        new_arr.push(item.bank_name);
        new_arr.push(item.disbursement_status);
        new_arr.push(moment(item.disburse_log_date).format("Do-MMM-YYYY"));

        sheetData.push(new_arr);
      });

      let ws = XLSX.utils.aoa_to_sheet(sheetData);

      wb.Sheets["Disbursements"] = ws;

      let wbSheet = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

      this.saveExcelSheet(this.convertBinaryToOctetStream(wbSheet));
    },
    convertBinaryToOctetStream(sheet) {
      let buf = new ArrayBuffer(sheet.length);
      let view = new Uint8Array(buf);

      for (let i = 0; i < sheet.length; i++) {
        view[i] = sheet.charCodeAt(i) & 0xff;
      }

      return buf;
    },
    saveExcelSheet(data) {
      const url = URL.createObjectURL(
        new Blob([data], { type: "application/vnd.ms-excel" })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "disbursement.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    pdfDownload() {
      const columns = [
        { title: "Customer Name", dataKey: "customer_name" },
        { title: "Loan Product", dataKey: "loan_product" },
        { title: "Amount", dataKey: "amount" },
        { title: "Account Name", dataKey: "account_name" },
        { title: "Bank Account", dataKey: "bank_account" },
        { title: "Bank Name", dataKey: "bank_name" },
        { title: "Status", dataKey: "status" },
        { title: "Creation Date", dataKey: "date" },
      ];

      let doc = new jsPDF("l", "pt", "a4");
      doc.setFontSize(6);
      doc.autoTable(columns, this.download);
      doc.save("disbursement.pdf");
    },

    getDisbursements(value) {
      this.fetchModel.pageSize = value;
      this.getAllDisbursements();
    },

    getAllDisbursements() {
      this.disburseLoading = true;
      this.$store.dispatch(GET_ALL_DISBURSEMENT, { ...this.fetchModel })
        .then(() => {
          this.disburseLoading = false;
          if(this.fetchModel.isFromFilter) {
            this.alertSuccess("Successfully filtered disbursement");
            this.getExportData();
          }
        })
        .catch((error) => {
          this.alertError(error.response.data.message);
        });
    },
    getExportData() {
      this.fetchModel.pageSize = this.paginations.totalCount;
      this.$store
        .dispatch(GET_ALL_DISBURSEMENT_EXPORTS, { ...this.fetchModel })
        .then(() => {
          this.alertSuccess("Successfully applied filter");
        })
        .catch((error) => {
          this.alertError(error.response.data.message);
        });
    },
    deleteFilter() {
      this.filterText = "";
      this.filterEdit = []
    },
    editFilter() {
      this.showEditedValue = Math.random()
      this.toggleFilterModal();
    },
    changePageIndex(value, pageSize) {
      this.changePage(value, pageSize);
    },
    changePage(type, pageSize) {
      if (type === "next") {
        this.fetchModel.pageIndex++;
        this.fetchModel.pageSize = pageSize;
      } else if (type === "previous") {
        this.fetchModel.pageIndex--;
        this.fetchModel.pageSize = pageSize;
      } else {
        this.fetchModel.pageIndex = type;
        this.fetchModel.pageSize = pageSize;
      }

      this.getAllDisbursements();
    },
    changeLength(e) {
      const { value } = e.target;
      this.fetchModel.pageSize = parseInt(value);
      this.fetchModel.pageIndex = 1;

      this.getAllDisbursements();
    },
    openRetryConfimationModal(key) {
      this.disbursementKey = key;
      this.openRetryModal = true;
    },
    closeRetryConfirmationModal() {
      this.disbursementKey = "";
      this.openRetryModal = false;
    },
    openCancelConfimationModal(key) {
      this.disbursementKey = key;
      this.openCancelModal = true;
    },
    closeCancelConfirmationModal() {
      this.disbursementKey = "";
      this.openCancelModal = false;
    },
    openActivateConfimationModal(key) {
      this.disbursementKey = key;
      this.openActivateModal = true;
    },
    closeActivateConfirmationModal() {
      this.disbursementKey = "";
      this.openActivateModal = false;
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    retryDisbursement() {
      try {
        this.isRetrying = true;
        let disbursementTransactionKey = this.disbursementKey;
        this.$store
          .dispatch(RETRY_DISBURSEMENT, { disbursementTransactionKey })
          .then((resp) => {
            this.isRetrying = false;
            this.openRetryModal = false;
            this.alertSuccess(resp.data.message);
            this.getAllDisbursements();
            this.disbursementKey = "";
          })
          .catch((error) => {
            this.isRetrying = false;
            this.openCancelModal = false;
            this.alertError(error.response.data.message);
          });
      } catch (e) {
        this.alertError(e);
        this.openRetryModal = false;
        this.isRetrying = false;
      }
    },
    cancelDisbursement() {
      try {
        this.isCancelling = true;
        let disbursementTransactionKey = this.disbursementKey;
        this.$store
          .dispatch(CANCEL_DISBURSEMENT, { disbursementTransactionKey })
          .then((resp) => {
            this.isCancelling = false;
            this.openCancelModal = false;
            this.alertSuccess(resp.data.message);
            this.getAllDisbursements();
            this.disbursementKey = "";
          })
          .catch((error) => {
            this.isCancelling = false;
            this.openCancelModal = false;
            this.alertError(error.response.data.message);
          });
      } catch (e) {
        this.alertError(e);
        this.openCancelModal = false;
        this.isCancelling = false;
      }
    },
    activateDisbursement() {
      try {
        this.isActivated = true;
        let disbursementTransactionKeys = [this.disbursementKey];
        this.$store
          .dispatch(ACTIVATE_DISBURSEMENT, { disbursementTransactionKeys })
          .then(() => {
            this.isActivated = false;
            this.openActivateModal = false;
            this.alertSuccess('Loan Activated Succesfully');
            this.getAllDisbursements();
            this.disbursementKey = "";
          })
          .catch((error) => {
            this.isActivated = false;
            this.openActivateModal = false;
            this.alertError(error.response.data.message);
          });
      } catch (e) {
        this.alertError(e);
        this.openActivateModal = false;
        this.isActivated = false;
      }
    },
    goToLoanDetails(disbursementKey, loanKey) {
      this.$router.push({ path: `/loan/accounts_details/${loanKey}/${disbursementKey}` });
    }
  },
  filters: {
    moment: function (date) {
      return date != null ? moment(date).format("Do-MMM-YYYY") : "";
    },
  },
  mounted() {
    this.getAllDisbursements();
  },
};
</script>
<style lang="scss" scoped>
._customtable {
  width: 100%;
}

._customtable thead th:first-of-type {
  width: 190px;
}
</style>
